<template>
  <layout :loading="loading">
    <template v-slot:content>
      <t-select
        class="mt-2 mb-4 w-32"
        :options="jobOptions"
        value-attribute="id"
        text-attribute="name"
        @change="onJobOptionChange"
      ></t-select>
      <div class="overflow-auto">
        <datatable :columns="columns" :data="users">
          <template scope="{ row }">
            <tr>
              <td class="h-8 whitespace-normal lg:whitespace-nowrap"
                :class="{ 'text-red-500': row.holiday_from }">
                {{ row.name }}
                <template  v-if="row.holiday_from">
                  <span class="whitespace-nowrap" v-if="row.enabled == 0">
                    ({{ $t('phonelist_inactive_user') }} | {{ $t('phonelist_holiday_user', { date: row.holiday_to | date }) }})
                  </span>
                  <span class="whitespace-nowrap" v-else>
                    (Afwezig tot: {{ row.holiday_to | date }})
                  </span>
                </template>
              </td>
              <td>{{ row.email }}</td>
              <td>{{ row.phone }}</td>
              <td>{{ row.jobs }}</td>
            </tr>
          </template>
        </datatable>
      </div>
    </template>
  </layout>
</template>

<script>
import Layout from "@/components/Layout.vue";
import { getPhoneListByFilter } from "@/services/user-service";
import moment from 'moment';

export default {
  components: { Layout },
  data: () => ({
    loading: false,
    users: [],
  }),
  mounted() {
    this.getPhoneList('all');
  },
  methods: {
    onJobOptionChange(jobOption) {
      if (typeof jobOption !== "string") {
        return;
      }

      this.getPhoneList(jobOption);
    },
    async getPhoneList(jobOption) {
      this.loading = true;
      try {
        const { data } = await getPhoneListByFilter(jobOption);
        this.users = data;
        console.log('DEBUG: users', this.users);
      } catch (error) {
        console.error('DEBUG: getPhoneList', error);
      }
      this.loading = false;
    }
  },
  computed: {
    jobOptions() {
      return [
        {
          id: 'all',
          name: this.$i18n.t('phone_list_all_option'),
        },
        {
          id: 'kitchen',
          name: this.$i18n.t('phone_list_kitchen_option'),
        },
        {
          id: 'dishes',
          name: this.$i18n.t('phone_list_dishes_option'),
        },
        {
          id: 'bar',
          name: this.$i18n.t('phone_list_bar_option'),
        }
      ];
    },
    columns() {
      return [
        { label: this.$i18n.t('phone_list_theader_name'), field: 'name' },
        { label: this.$i18n.t('phone_list_theader_email'), field: 'email' },
        { label: this.$i18n.t('phone_list_theader_phone'), field: 'phone' },
        { label: this.$i18n.t('phone_list_theader_jobs'), field: 'jobs' },
      ]
    }
  },
  filters: {
    date(date) {
      return moment(date).format('DD-MM-YYYY');
    }
  }
};
</script>

<style lang="scss" scoped>
table {
  border: 1px solid #ddd;
  width: 100%;

  &::v-deep th {
    text-align: left !important;
    background-color: #ddd;
    border-right: 1px solid white;
    font-size: 14px;
    padding: 5px 5px 5px 10px;
    min-width: 100px;
    width: 25%;
  }

  &::v-deep tbody > tr {
    &:hover, &:nth-child(odd) {
      background-color: #f9f9f9;
    }

    td {
      vertical-align: top;
      font-size: 13px;
      padding: 5px 5px 5px 10px;
    }
  }
}
</style>
